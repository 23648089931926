/* eslint-disable @typescript-eslint/dot-notation */
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import _ from 'lodash';

import { IUser, UserRole, UserType } from 'app/shared/models/user.interface';

import { UserState, userStateKey } from 'app/store/reducers/user.reducer';

export const getUserState = createFeatureSelector<UserState>(userStateKey);

export const getUser = createSelector(getUserState, (state: UserState) => state.user);
export const getUserLoaded = createSelector(getUserState, (state: UserState) => state.userLoaded);
export const getUserLoading = createSelector(getUserState, (state: UserState) => state.userLoading);
export const getUserErred = createSelector(getUserState, (state: UserState) => state.userErred);

export const getUserSecurity = createSelector(getUserState, (state: UserState) => state.security);
export const getUserAccountStatus = createSelector(getUserState, (state: UserState) => state.accountStatus);

export const getUserFeatureCodes = createSelector(getUser, getUserSecurity, (user, security): string[] => {
  if (!user || !security) {
    return [];
  }

  const activeCustID: number = user.activeCustID || 14; // make this the real custID
  const custSecurityObject = _.find(security.securityObjects, {
    customerID: activeCustID
  })!;

  return _.flow(
    (fs) => _.map(fs, (featureSet) => _.map(featureSet.features, 'code')),
    _.flatten,
    _.uniq
  )(custSecurityObject.securityObjects);
});

export const getUserRole = createSelector(getUser, (user: IUser) => {
  if (user.rbmID) {
    return UserRole.RBM;
  } else if (user.atlID) {
    return UserRole.ATL;
  } else if (user.dsmID) {
    return UserRole.SA;
  } else if (!user.dsmID && user.type === UserType.AD_INTERNAL) {
    return UserRole.INTERNAL_USER;
  } else if (user.activeCustID !== 14 && user.type === UserType.AD_EXTERNAL) {
    return UserRole.DEALER;
  } else {
    return UserRole.CUSTOMER;
  }
});

export const getUserIsExternal = createSelector(getUserState, (userState) => {
  return Boolean(userState.user.activeCustID && userState.user.activeCustID !== 0 && userState.user.activeCustID !== 14);
});

export const getUserHasFeatureCode = (role: string) =>
  createSelector(getUserFeatureCodes, (features) => {
    return features.indexOf(role) >= 0;
  });

export const getUserHasFeatureCodes = (roles: string[]) =>
  createSelector(getUserFeatureCodes, (features) => {
    return _.intersection(features, roles).length > 0;
  });

export const getUserIsInternal: MemoizedSelector<object, boolean> = createSelector(getUserState, (userState: UserState) => {
  return Boolean(userState && userState.user && userState.user.type === UserType.AD_INTERNAL);
});

export const getUserIsSeedAdvisor: MemoizedSelector<object, boolean> = createSelector(getUserState, (userState: UserState) => {
  return Boolean(userState && userState.user && userState.user.dsmID && userState.user.atlID == null && userState.user.rbmID == null);
});

export const getUserActiveCustID = createSelector(getUser, (user) => user.activeCustID);

export const getUserIsDealerSalesRep: MemoizedSelector<object, boolean> = createSelector(getUser, (user: IUser) => {
  return Boolean(user.salesRepDlrID);
});

export const getUserDealerSalesRepID: MemoizedSelector<object, number | null> = createSelector(getUser, (user: IUser) => {
  return user.salesRepDlrID && user.activeCustID ? user.activeCustID : null;
});

export const getUserIsDealer: MemoizedSelector<object, boolean> = createSelector(
  getUserIsExternal,
  getUserIsDealerSalesRep,
  (userIsExternal: Boolean, userIsDealerSalesRep) => {
    return Boolean(userIsExternal && !userIsDealerSalesRep);
  }
);

export const getUserDlrSalesRepDlrAsDlrIDQueryParamObj: MemoizedSelector<object, { dlrID: number } | null> = createSelector(
  getUser,
  (user: IUser) => {
    return user.salesRepDlrID && user.activeCustID ? { dlrID: user.activeCustID } : null;
  }
);
